@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
	font-family: 'qanelas_eb';
	src: local('qanelas-extrabold'), url('./assets/fonts/QanelasExtraBold.otf') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'qanelas_sb';
	src: local('qanelas-semibold'), url('./assets/fonts/QanelasSemiBold.otf') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'qanelas_b';
	src: local('qanelas-bold'), url('./assets/fonts/QanelasBold.otf') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'qanelas_m';
	src: local('qanelas-medium'), url('./assets/fonts/QanelasMedium.otf') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'qanelas_r';
	src: local('qanelas-regular'), url('./assets/fonts/QanelasRegular.otf') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'qanelas_t';
	src: local('qanelas-light'), url('./assets/fonts/QanelasThin.otf') format('woff2');
	font-display: swap;
}

body {
  margin: 0;
  background-color: #F2F4F7;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  @apply font-qanelas_r;
	box-sizing: border-box;
  color: white;
  font-size: 16px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  color: black;
}


@keyframes appear {
	from {
		opacity: 0;
		scale: 0.5;
	}
	to {
		opacity: 1;
		scale: 1;
	}
}

.animate_zoom {
	animation: appear 2s linear;
}